import React from 'react'
import { Link } from 'gatsby'
import { TbBow, TbChartBar, TbLocation, TbPokeball, TbSwords  } from 'react-icons/tb'
import { usePokedex } from '../../context/PokedexContext'
import { ActionToggler } from './ActionToggler'
import { useTranslations } from '../../context/TranslationsContext'

export const PokemonActionBar = ({ locationList, active, onClick, pokemonName, pokemonId }) => {
    const { TABS } = usePokedex()
    const { t } = useTranslations();
    return (
        <div className="d-flex" style={{ gap: ".5rem" }}>
            <>
                {
                    locationList.length
                        ? <ActionToggler
                            active={active === TABS.LOCATION ? true : false}
                            onClick={() => onClick(TABS.LOCATION)}
                            icon={<TbLocation />}
                            title={t("locations")}
                        />
                        : false
                }
                <ActionToggler
                    active={active === TABS.MOVES ? true : false}
                    onClick={() => onClick(TABS.MOVES)}
                    icon={<TbBow />}
                    title={t("learnset")}
                />
                <ActionToggler
                    active={active === TABS.STATS ? true : false}
                    onClick={() => onClick(TABS.STATS)}
                    icon={<TbChartBar />}
                    title={t("stats")}
                />
                <ActionToggler
                    // active={active === TABS.CATCH_RATE ? true : false}
                    // onClick={() => onClick(TABS.CATCH_RATE)}
                    onClick={() => {}}
                    icon={<TbPokeball />}
                    title={t("catch rates")}
                    as={Link}
                    to="/tools/catch-rate-calculator"
                    state={{ name: pokemonName, id: pokemonId }}
                />
                <ActionToggler
                    onClick={() => window.open('https://www.smogon.com/dex/bw/pokemon/' + pokemonName.toLowerCase() + '/')}
                    icon={<TbSwords />}
                    title={t("smogon")}
                />
            </>
        </div>

    )
}